/* open-sans-regular - latin */
@font-face {
font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
src: url('/assets/fonts/open-sans-v16-latin-regular.eot'); /* IE9 Compat Modes */
src: local('Open Sans Regular'), local('OpenSans-Regular'),
     url('/assets/fonts/open-sans-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
     url('/assets/fonts/open-sans-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
     url('/assets/fonts/open-sans-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
     url('/assets/fonts/open-sans-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
     url('/assets/fonts/open-sans-v16-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-700 - latin */
@font-face {
font-family: 'Open Sans';
font-style: normal;
font-weight: 700;
src: url('/assets/fonts/open-sans-v16-latin-700.eot'); /* IE9 Compat Modes */
src: local('Open Sans Bold'), local('OpenSans-Bold'),
     url('/assets/fonts/open-sans-v16-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
     url('/assets/fonts/open-sans-v16-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
     url('/assets/fonts/open-sans-v16-latin-700.woff') format('woff'), /* Modern Browsers */
     url('/assets/fonts/open-sans-v16-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
     url('/assets/fonts/open-sans-v16-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* zilla-slab-regular - latin */
@font-face {
font-family: 'Zilla Slab';
font-style: normal;
font-weight: 400;
src: url('/assets/fonts/zilla-slab-v4-latin-regular.eot'); /* IE9 Compat Modes */
src: local('Zilla Slab'), local('ZillaSlab-Regular'),
     url('/assets/fonts/zilla-slab-v4-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
     url('/assets/fonts/zilla-slab-v4-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
     url('/assets/fonts/zilla-slab-v4-latin-regular.woff') format('woff'), /* Modern Browsers */
     url('/assets/fonts/zilla-slab-v4-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
     url('/assets/fonts/zilla-slab-v4-latin-regular.svg#ZillaSlab') format('svg'); /* Legacy iOS */
}

$font-main: "Open Sans", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !default;
$font-title: "Zilla Slab", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !default;

$color-bg: #111;

$background-opacity-start: 0.0;
$background-opacity-home-start: 0.0;