header .site-title{
    font-weight: 400;
    font-size: 1.5rem;
}
h1, h2, h3, h4, h5, h6{
    font-weight: 400;
}
.subtext{
    opacity: 0.75;
    font-size: 0.8rem;
    margin-top: 2rem;
}

main article .page-content .page-content-main .adjacent-links{
    margin-top: 4rem;

    padding-left: 0;
    list-style: none;

    display: flex;
    flex-direction: row;

    .link{
        flex: 1 0 50%;
        a{
            display: block;
            border: none;
            border-top: 1px dotted currentColor;
            padding-top: 1rem;
            color: currentColor;
            &:focus, &:hover{
                color: #fff;
                border-top: 1px solid currentColor;
            }
        }
        .link-description{
            opacity: 0.5;
        }
    }

    .link-next{
        order: 1;
        text-align: right;
        .link-description{
            &::after{
                content: '>';
                transform: scaleX(0.5);
            }
        }
    }
    .link-previous{
        order: 0;
        text-align: left;
        .link-description{
            &::before{
                content: '<';
                transform: scaleX(0.5);
            }
        }
    }
}

.footnotes{
    padding-top: 2rem;
    font-size: 80%;
    ol{
        padding: 1rem 0;
        border-top: 1px dotted currentColor;
        list-style-position: inside;
        li{
            p{
                display: inline;
            }
        }
    }
}

.pills{
    line-height: 2;

    input[type=radio]{
        width: 0;
        height: 0;
        opacity: 0;
    }
    label{
        font-weight: normal;
        cursor: pointer;
        border: 1px solid currentColor;
        border-radius: 0.65rem;
        padding: 0 1rem;
        line-height: 1.5;
        opacity: 0.75;
        .count
        {
            opacity: 0.5;
            padding-left: 0.5rem;
        }
    }
    
    input[type=radio]:checked + label{
        color: #fff;
        opacity: 1;
    }
}